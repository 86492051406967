<!--
 * @Description: 模板之外 数据渲染
 * @Author: 梁平贤
 * @LastEditors: wuqi
 * @Date: 2019-09-11 11:30:49
 * @LastEditTime: 2021-07-08 10:04:03
 -->
<template>
  <el-form-item
    :label="showLabelName"
    label-width="auto"
    class="business-column-rendering"
    :required="require ? column.required : false"
    :style="columnStyleWidth"
    :prop="checkProp"
    :rules="submitRules"
  >
    <component
      :is="_renderingComponent"
      :column="column"
      :business-data="businessData"
      :checkProp="checkProp"
    ></component>
  </el-form-item>
</template>
<script>
import MxColumnData from "./MxColumnData.js";
import MxSubmitRules from "../../mixins/MxSubmitRules.js";
import { components, renderingComponent } from "./componentsConfig";

export default {
  name: "BusinessColumnRendering",
  components,
  mixins: [MxColumnData, MxSubmitRules],
  props: {
    // 绑定的数据源
    column: {
      type: Object,
      default() {
        return {};
      }
    },
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    // prop校验路径
    checkProp: {
      type: String,
      default: ""
    },
    require: Boolean
  },
  data() {
    return {};
  },
  created() {
    console.log(2222, this.checkProp, this.column, this.businessData);
  },
  computed: {
    // 控制渲染的
    _renderingComponent() {
      return renderingComponent(this.column.fieldType);
    },
    showLabelName() {
      if ([5, 15, 33].includes(Number(this.column.fieldType))) {
        if (this.column.field === "id") {
          return this.column.relationTempName || this.column.name;
        }
      }
      return this.column.name;
    }
  }
};
</script>

<style scoped lang="scss">
  .business-column-rendering {
    display: flex;
    flex-flow: row;
    min-width: 200px;
    margin-bottom: 0;

    & /deep/ .el-form-item__label {
      flex-shrink: 0;
    }
    /*label-width:auto需要这么重置*/
    & /deep/ .el-form-item__label-wrap {
      margin-left: 0 !important;
      flex-shrink: 0;
    }
    & /deep/ .el-form-item__content {
      flex: 1;
      margin-left: 0 !important;
      width: 0;
    }
  }
</style>
