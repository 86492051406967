var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    {
      staticClass: "business-column-rendering",
      style: _vm.columnStyleWidth,
      attrs: {
        label: _vm.showLabelName,
        "label-width": "auto",
        required: require ? _vm.column.required : false,
        prop: _vm.checkProp,
        rules: _vm.submitRules,
      },
    },
    [
      _c(_vm._renderingComponent, {
        tag: "component",
        attrs: {
          column: _vm.column,
          "business-data": _vm.businessData,
          checkProp: _vm.checkProp,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }